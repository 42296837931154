
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import FilterBox from "@/components/FilterBox.vue";
import Entitytable from "@/components/EntityTable.vue";
import moment from "moment";

export default defineComponent({
  name: "ll-lead-search",
  components: {
    Entitytable,
    FilterBox,
  },
  methods: {
    getDate(date) {
      return moment(date).format("YYYY-MM-DD h:mm:ss a");
    },
  },
  data() {
    return {
      filters: {
        dateBetween: [],
      },
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Sales");
    });

    return {};
  },
});
